import React from "react";
import "./Button.css";
import { Link } from "react-router-dom";

export function Button() {
  return (
    <Link to="/contact">
      <button className="button" onClick={() => window.scrollTo(0, 0)}>
        Contact Us
      </button>
    </Link>
  );
}
