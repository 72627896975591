import React from "react";
import "./Testimonials.css";
import { Parallax } from "react-parallax";
import testimonials from "../images/Testimonials.jpg";
import { Zoom } from "react-reveal";
import Footer from "../Footer";
import { Fade } from "react-reveal";
import Bounce from "react-reveal/Bounce";

function Testimonials() {
  return (
    <div className="testimonials-body">
      <head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <script src="https://kit.fontawesome.com/a076d05399.js"></script>
      </head>
      <div className="Testimonials-intro">
        <Parallax
          bgImage={testimonials}
          bgImageAlt="Testimonials"
          strength={300}
        >
          <h1>
            {" "}
            <Zoom>Testimonials</Zoom>
          </h1>
        </Parallax>
      </div>
      <div class="testimonial-container">
        <ul class="grid-testimonials">
          <li>
            <Bounce bottom>
              <div>
                <p>
                  <i className="fa fa-quote-left" />
                  Mr Assefa offered a competent service and guided me through
                  what is a complex Ethiopian bureaucracy, advising, advocating
                  and translating for me. Mr Assefa went beyond the engagement
                  scope to ensure he offered a comprehensive service.
                  <i className="fa fa-quote-right" />
                </p>
                <span class="quote-arrow"></span>
              </div>
            </Bounce>
            <span class="grid-testimonials-citation">
              Posted by a Private Individual on www.martindale.com
            </span>
          </li>
          <li>
            <Bounce bottom>
              <div>
                <p>
                  <i className="fa fa-quote-left" />
                  They are practical and understand the law and the business
                  environment. Therefore they give advice that is realistic and
                  easy to implement. They take time to understand a client's
                  need before the offer a solution. The firm consists of young
                  and energetic lawyers. Highly recommended.
                  <i className="fa fa-quote-right" />
                </p>
                <span class="quote-arrow"></span>
              </div>
            </Bounce>
            <span class="grid-testimonials-citation">
              Posted by a Corporate/Large Company on www.martindale.com
            </span>
          </li>
          <li>
            <Bounce bottom>
              <div>
                <p>
                  <i className="fa fa-quote-left" />
                  Mr. Tamrat Assefa is a very professional and courteous lawyer.
                  He provided me with very precise details about my case through
                  email, and when I went to Addis he picked me up from the
                  airport. His fees were reasonable for the service provided,
                  and I would highly recommend him for any of your legal needs!
                  <i className="fa fa-quote-right" />
                </p>
                <span class="quote-arrow"></span>
              </div>
            </Bounce>
            <span class="grid-testimonials-citation">
              Posted by a Corporation on www.lawyers.com
            </span>
          </li>
          <li>
            <Bounce bottom>
              <div>
                <p>
                  <i className="fa fa-quote-left" />
                  Friendly and easy to communicate, very responsive to
                  inquiries, professional in trademark application prosecution,
                  offer competitive price for services.
                  <i className="fa fa-quote-right" />
                </p>
                <span class="quote-arrow"></span>
              </div>
            </Bounce>
            <span class="grid-testimonials-citation">
              Posted by a Corporation on www.lawyers.com
            </span>
          </li>
          <li>
            <Bounce bottom>
              <div>
                <p>
                  <i className="fa fa-quote-left" />
                  Professional services at competitive price and respond to
                  clients' inquiries fast.
                  <i className="fa fa-quote-right" />
                </p>
                <span class="quote-arrow"></span>
              </div>
            </Bounce>
            <span class="grid-testimonials-citation">
              Posted by a Corporate/Large Company on www.martindale.com
            </span>
          </li>
        </ul>
      </div>
      <div className="more-reviews">
        <Fade bottom>
          <h1>To see more client reviews click on the buttons below</h1>
        </Fade>
        <div className="more-reviews-links">
          <Fade>
            <a href="http://www.martindale.com/Tamrat-Assefa-Liban-Law-Offices/law-firm-157391825-client-reviews.htm">
              Review 1
            </a>
          </Fade>
          <Fade>
            <a href="https://web.archive.org/web/20210927104337/http://www.lawyers.com/addis-ababa/ethiopia/tamrat-assefa-liban-158466519-a/">
              Review 2
            </a>
          </Fade>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Testimonials;
