import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <script src="https://kit.fontawesome.com/a076d05399.js"></script>
      </head>
      <body>
        <div class="footer">
          <div class="inner-footer">
            <div class="footer-items">
              <h1>Tamrat Assefa Liban Law Office</h1>
              <p>Established. Effective. Reliable</p>
            </div>

            <div class="footer-items">
              <h3>Quick Links</h3>
              <div class="border1"></div>
              <ul>
                <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                  <li>Home</li>
                </Link>
                <Link to="/practice" onClick={() => window.scrollTo(0, 0)}>
                  <li>Expertise</li>
                </Link>
                <Link to="/team" onClick={() => window.scrollTo(0, 0)}>
                  <li>The Team</li>
                </Link>
                <Link to="/testimonials" onClick={() => window.scrollTo(0, 0)}>
                  <li>Testimonials</li>
                </Link>
                <Link to="/contact" onClick={() => window.scrollTo(0, 0)}>
                  <li>Contact Us</li>
                </Link>
              </ul>
            </div>
            <div class="footer-items">
              <h3>Address</h3>
              <div class="border1"></div>
              <ul>
                <li>Robel Plaza, 4th Floor, Off Africa</li>

                <li>Avenue, Bole Medhanialem</li>

                <li>P. O. Box 1151 code 1250</li>

                <li>Addis Ababa, Ethiopia</li>
              </ul>
            </div>
            <div class="footer-items">
              <h3>Contact us</h3>
              <div class="border1"></div>
              <ul>
                <a href="tel:+251116670506">
                  <li>
                    <i class="fa fa-phone" aria-hidden="true"></i> +251 11 667
                    0506
                  </li>
                </a>
                <a href="tel:+2516670809">
                  <li>
                    <i class="fa fa-phone" aria-hidden="true"></i> +251 11 667
                    0809
                  </li>
                </a>
                <a href="tel:+251925085597">
                  <li>
                    <i class="fa fa-mobile" aria-hidden="true"></i> +251 925
                    0855 97
                  </li>
                </a>
                <a href="tel:+251911642169">
                  <li>
                    <i class="fa fa-mobile" aria-hidden="true"></i> +251 911 642
                    169
                  </li>
                </a>
                <a href="mailto:info@ethiolegal.com">
                  <li>
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    info@ethiolegal.com
                  </li>
                </a>
              </ul>
              <div class="social-media">
                <a href="https://twitter.com/ethiolegal">
                  <i class="fa fa-twitter" aria-hidden="true"></i>
                </a>
                <a href="https://www.linkedin.com/company/tamrat-assefa-liban-law-office?report%2Esuccess=KJ_KkFGTDCfMt-A7wV3Fn9Yvgwr02Kd6AZHGx4bQCDiP6-2rfP2oxyVoEQiPrcAQ7Bf">
                  <i class="fa fa-linkedin" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="footer-bottom">
            Copyright &copy; Tamrat Assefa Liban Law Office. All Rights
            Reserved.
          </div>
          <div class="footer-bottom">
            Developed by{" "}
            <a href="mailto:michaelabebe19922000@gmail.com">
              {" "}
              Mike's Web Services
            </a>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Footer;
