import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import ELA from "../images/ELA.jpg";
import IBA from "../images/IBA.png";
import Legal from "../images/Legal.png";
import CG from "../images/CG.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Zoom from "react-reveal/Zoom";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../Footer";
import { Parallax } from "react-parallax";
import Library from "../images/Archive5.jpg";
import Archive from "../images/LawArchive.webp";
import "./Home.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default function Home() {
  const [text, setText] = useState("text");
  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      setText("text");
    } else if (window.scrollY > 250) {
      setText("text2");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  return (
    <div>
      <head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <script src="https://kit.fontawesome.com/a076d05399.js"></script>
      </head>
      <section class="showcase">
        <div class={text}>
          <Fade left>
            <h3>Leading law practice in ethiopia</h3>
            <p>Established. Effective. Reliable</p>
          </Fade>
          <Fade>
            <Link to="/practice" onClick={() => window.scrollTo(0, 0)}>
              Read More
            </Link>
          </Fade>
        </div>
      </section>
      <div className="restPage">
        <section class="about-section">
          <div class="inner-container">
            <Fade bottom>
              <h1>About Us</h1>
            </Fade>
            <p class="about-section-text">
              Tamrat Assefa Liban Law Office is a legal practice specialized in
              serving international clients in various areas of law with an
              emphasis in commercial law, mining and energy law, banking &
              finance, mergers & acquisitions, aviation law, construction law,
              investment law and intellectual property law.
            </p>
            <Fade>
              <Link to="/practice" onClick={() => window.scrollTo(0, 0)}>
                Learn More
              </Link>
            </Fade>
          </div>
        </section>
        <section className="testimonial-section">
          <Fade bottom>
            <h1>Testimonials</h1>
          </Fade>
          <div className="container">
            <Zoom>
              <div className="row">
                <Swiper
                  spaceBetween={50}
                  slidesPerView={3}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  navigation={false}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 40,
                    },
                    1000: {
                      slidesPerView: 3,
                      spaceBetween: 50,
                    },
                  }}
                >
                  <SwiperSlide>
                    <div className="col-lg-12">
                      <div className="testi-item">
                        <div className="testi-comment">
                          <p>
                            <i className="fa fa-quote-left" />
                            Mr. Tamrat Assefa is a very professional and
                            courteous lawyer. He provided me with very precise
                            details about my case through email, and when I went
                            to Addis he picked me up from the airport. His fees
                            were reasonable for the service provided, and I
                            would highly recommend him for any of your legal
                            needs!
                            <i className="fa fa-quote-right" />
                          </p>
                          <ul className="stars list-unstyled">
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                          </ul>
                        </div>
                        <div className="client-info">
                          <h5>martindale.com</h5>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="col-lg-12">
                      <div className="testi-item">
                        <div className="testi-comment">
                          <p>
                            <i className="fa fa-quote-left" />
                            They are practical and understand the law and the
                            business environment. Therefore they give advice
                            that is realistic and easy to implement. They take
                            time to understand a client's need before the offer
                            a solution. The firm consists of young and energetic
                            lawyers. Highly recommended.
                            <i className="fa fa-quote-right" />
                          </p>
                          <ul className="stars list-unstyled">
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                          </ul>
                        </div>
                        <div className="client-info">
                          <h5>martindale.com</h5>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="col-lg-12">
                      <div className="testi-item">
                        <div className="testi-comment">
                          <p>
                            <i className="fa fa-quote-left" />
                            He is exceptional. He is very detailed and
                            efficient, and is very good at explaining the law.
                            <i className="fa fa-quote-right" />
                          </p>
                          <ul className="stars list-unstyled">
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                          </ul>
                        </div>
                        <div className="client-info">
                          <h5>chambersandpartners.com</h5>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Zoom>
          </div>
        </section>
        <Parallax bgImage={Library} bgImageAlt="Library" strength={300}>
          <section className="external-link">
            <div class="fx-card">
              <div class="fx-card__img">
                <img src={Archive} alt="" />
              </div>
              <div class="fx-card__info">
                <h1 class="fx-card__title">African Law Archive</h1>
                <p class="fx-card__text">
                  The African Law Archive is the premier destination for legal
                  research in Ethiopia. With the most complete and up-to-date
                  catalog of Ethiopian laws, statutes, and regulations, the
                  African Law Archive is the ultimate resource for scholars,
                  practitioners, and anyone interested in the legal system of
                  Ethiopia.
                </p>
                <Fade>
                  <a href="https://africanlawarchive.com/index.cfm?a667F39819FA696259D97DD110D632686569CBB8B=F145B7293D6FAEFFFC29F1EE10B85015">
                    Go to Archive
                  </a>
                </Fade>
              </div>
            </div>
          </section>
        </Parallax>
        <section className="member-of">
          <Fade bottom>
            <h1>Member of</h1>
          </Fade>
          <div class="cards-list">
            <div class="card 1 card1">
              <div class="card_image">
                {" "}
                <img src={ELA} alt="Ethiopian lawyers association" />{" "}
              </div>
            </div>

            <div class="card 2 card2">
              <div class="card_image">
                <img src={IBA} alt="Ethiopian bar association" />
              </div>
            </div>
          </div>
        </section>
        <section className="ranked-by">
          <Fade bottom>
            <h1>Ranked by</h1>
          </Fade>
          <div class="cards-list">
            <div class="card 4 card4">
              <div class="card_image">
                {" "}
                <img src={CG} alt="Chambers Global" />{" "}
              </div>
            </div>
            <div class="card 3">
              <div class="card_image card3">
                <img src={Legal} alt="Legal 500" />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
