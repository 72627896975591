import React, { useState } from "react";
import "./Contact.css";
import Footer from "../Footer";
import Fade from "react-reveal";
import { Zoom } from "react-reveal";
import CheckIcon from '@mui/icons-material/Check';

function Contact() {

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function DisplayName() {
    if (fullName.length === 0) {
      return "";
    }
    if (!fullName.match(/^[A-Za-z]*\s{1}[A-Za-z]*$/)) {
      return "Write full name";
    }
    return <CheckIcon className="check-icon" />;
  }

  function DisplayEmail() {
    if (email.length === 0) {
      return "";
    }
    // eslint-disable-next-line
    if (!email.match(/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/)) {
      return "Email Invalid";
    }
    return <CheckIcon className="check-icon" />;
  }

  function DisplayMessage() {
    var required = 30;
    var left = required - message.length;

    if (message.length === 0) {
      return "";
    }

    if (left > 1) {
      return left + " more characters required";
    }

    return <CheckIcon className="check-icon" />;
  }
  function validateName() {
    if (fullName.length === 0) {
      return false;
    }
    if (!fullName.match(/^[A-Za-z]*\s{1}[A-Za-z]*$/)) {
      return false;
    }
    return true;
  }

  function validateEmail() {
    if (email.length === 0) {
      return false;
    }
    // eslint-disable-next-line
    if (!email.match(/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/)) {
      return false;
    }
    return true;
  }

  function validateMessage() {
    var required = 30;
    var left = required - message.length;

    if (message.length === 0) {
      return false;
    }

    if (left > 1) {
      return false;
    }

    return true;
  }

  const handleSubmit = (event) => {
    if (!validateName() || !validateEmail() || !validateMessage()) {
      alert("Please fill all the inputs");
      event.preventDefault();
    } else {
    }
  };


  return (
    <div>
      <head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <script src="https://kit.fontawesome.com/a076d05399.js"></script>
      </head>
      <section className="contact-us">
        <div class="content">
          <div class="contact">
            <div class="other">
              <div class="info">
                <Fade bottom>
                  <h2>More Methods </h2>
                </Fade>
                <h3>Address</h3>
                <Fade left>
                  <p>Tamrat Assefa Liban Law Office</p>
                  <p>Robel Plaza, 4th Floor, Room 405 </p>
                  <p>Off Africa Avenue, Bole Medhane-alem area</p>
                  <p>P.O Box 1151 code 1250</p>
                  <p>Addis Ababa, Ethiopia</p>
                </Fade>
                <h3>Phone</h3>

                <div class="svg-wrap-phone">
                  <Fade left>
                    <a href="tel:+251116670506">
                      <i class="fa fa-phone" aria-hidden="true"></i> +251 11 667
                      0506
                    </a>

                    <a href="tel:+2516670809">
                      <i class="fa fa-phone" aria-hidden="true"></i> +251 667
                      0809
                    </a>
                    <a href="tel:+251925085597">
                      {" "}
                      <i class="fa fa-mobile" aria-hidden="true"></i> +251 925
                      0855 97
                    </a>
                    <a href="tel:+251911642169">
                      <i class="fa fa-mobile" aria-hidden="true"></i> +251 911
                      642 169
                    </a>
                  </Fade>
                </div>
                <h3>Email</h3>
                <div class="svg-wrap">
                  <Fade left>
                    <a href="mailto:info@ethiolegal.com">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 485.211 485.211"
                      >
                        <path d="M485.21 363.906c0 10.637-2.99 20.498-7.784 29.174l-153.2-171.41 151.54-132.584c5.894 9.355 9.445 20.344 9.445 32.22v242.6zM242.607 252.793l210.863-184.5c-8.654-4.737-18.398-7.642-28.91-7.642H60.65c-10.523 0-20.27 2.906-28.888 7.643l210.844 184.5zm58.787-11.162l-48.81 42.735c-2.854 2.487-6.41 3.73-9.977 3.73-3.57 0-7.125-1.243-9.98-3.73l-48.82-42.736-155.14 173.6c9.3 5.834 20.198 9.33 31.984 9.33h363.91c11.785 0 22.688-3.496 31.984-9.33l-155.15-173.6zM9.448 89.086C3.554 98.44 0 109.43 0 121.305v242.602c0 10.637 2.978 20.498 7.79 29.174L160.97 221.64 9.448 89.086z" />
                      </svg>
                      info@ethiolegal.com
                    </a>
                  </Fade>
                </div>
                <h3>Connect</h3>

                <div class="svg-wrap">
                  <Zoom>
                    <a href="https://twitter.com/ethiolegal">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 612 612"
                      >
                        <path d="M612 116.258c-22.525 9.98-46.694 16.75-72.088 19.772 25.93-15.527 45.777-40.155 55.184-69.41-24.322 14.378-51.17 24.82-79.775 30.48-22.906-24.438-55.49-39.66-91.63-39.66-69.333 0-125.55 56.218-125.55 125.514 0 9.828 1.11 19.427 3.25 28.606-104.325-5.24-196.834-55.223-258.75-131.174-10.822 18.51-16.98 40.078-16.98 63.1 0 43.56 22.182 81.994 55.836 104.48-20.575-.688-39.926-6.348-56.867-15.756v1.568c0 60.806 43.29 111.554 100.692 123.104-10.517 2.83-21.607 4.398-33.08 4.398-8.107 0-15.947-.803-23.634-2.333 15.985 49.907 62.336 86.2 117.253 87.194-42.946 33.655-97.098 53.656-155.915 53.656-10.134 0-20.116-.612-29.944-1.72 55.568 35.68 121.537 56.484 192.44 56.484 230.947 0 357.187-191.29 357.187-357.188l-.42-16.253C573.87 163.525 595.21 141.42 612 116.257z" />
                      </svg>
                    </a>
                  </Zoom>
                  <Zoom>
                    <a href="https://www.linkedin.com/company/tamrat-assefa-liban-law-office?report%2Esuccess=KJ_KkFGTDCfMt-A7wV3Fn9Yvgwr02Kd6AZHGx4bQCDiP6-2rfP2oxyVoEQiPrcAQ7Bf">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 478.165 478.165"
                      >
                        <path d="M442.78 0H35.424C15.86 0 0 15.4 0 34.288v409.688c0 18.828 15.86 34.19 35.424 34.19H442.76c19.586 0 35.405-15.362 35.405-34.19V34.288C478.165 15.4 462.345 0 442.78 0zM145.003 400.244H72.78V184.412h72.224v215.832zm-36.16-245.28h-.48c-24.246 0-39.926-16.695-39.926-37.336 0-21.22 16.158-37.337 40.863-37.337 24.725 0 39.927 16.12 40.385 37.338.02 20.64-15.64 37.337-40.843 37.337zm296.54 245.28h-72.082V284.807c0-29.01-10.598-48.952-36.738-48.952-20.063 0-31.798 13.428-36.958 26.458-1.893 4.423-2.39 10.898-2.39 17.393v120.537H184.95s.916-195.63 0-215.832h72.263v30.604c9.484-14.684 26.658-35.703 65.01-35.703 47.577 0 83.16 30.863 83.16 97.168v123.766zm-148.61-184.532c.06-.22.16-.438.42-.677v.677h-.42z" />
                      </svg>
                    </a>
                  </Zoom>
                </div>
              </div>
            </div>
            <div class="form">
              <Fade bottom>
                <h1>Get In Touch</h1>
              </Fade>
              <form   
              action="https://formsubmit.co/info@ethiolegal.com"
              method="POST"
              onSubmit={handleSubmit}>
                <div class="flex-rev">
                  <input
                    className="frm-txt"
                    type="text"
                    placeholder="full name"
                    name="firstName"
                    id="contact-name"
                    onChange={(e) => setFullName(e.target.value)}
                  />
                  <label for="name">Full Name</label>
                </div>
                <p>{DisplayName()}</p>
                <div class="flex-rev">
                  <input
                    className="frm-email"
                    type="email"
                    placeholder="e-mail"
                    name="email"
                    id="contact-email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label for="email">Your Email</label>
                </div>
                <p>{DisplayEmail()}</p>
                <div class="flex-rev">
                  <textarea
                    name="message"
                    id="contact-message"
                    cols="30"
                    rows="8"
                    placeholder="message"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <label for="message">Email Message</label>
                </div>
                <p>{DisplayMessage()}</p>
                <button onclick="return validateForm()">Submit</button>
                <span id="submit-error"></span>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Contact;
