import React from "react";
import "./Team.css";
import Team from "../images/Team.jpg";
import { Zoom } from "react-reveal";
import { Parallax } from "react-parallax";
import Fade from "react-reveal/Fade";
import Footer from "../Footer";

function team() {
  return (
    <div>
      <div className="Team">
        <div className="team-intro">
          <Parallax bgImage={Team} bgImageAlt="Team" strength={300}>
            <h1>
              {" "}
              <Zoom>Team</Zoom>
            </h1>
          </Parallax>
        </div>
        <body>
          <div class="flip-card">
            <div class="flip-card-front">
              <div class="inner">
                <Fade bottom>
                  <h3>Tamrat Assefa</h3>
                  <p>Managing Partner</p>
                </Fade>
              </div>
            </div>

            <div class="flip-card-back">
              <div class="inner">
                <p>
                  Tamrat Assefa is the managing partner at Tamrat Assefa Liban
                  Law Office. He has over 21 years of professional experience in
                  the practice of Ethiopian law where he has served as a judge
                  of the Federal First Instance Court and later as a
                  practitioner since his admission to the bar in the year 2002.
                  Tamrat advises clients in various areas of practice with an
                  emphasis in commercial law, banking and finance, foreign
                  investment law, energy and natural resources law and
                  intellectual property law. He is a member of the Ethiopian Bar
                  Association and the International Bar Association.
                </p>
              </div>
            </div>
          </div>
          <div class="flip-card">
            <div class="flip-card-front">
              <div class="inner">
                <Fade bottom>
                  <h3>Marina Bwile</h3>
                  <p>Director</p>
                </Fade>
              </div>
            </div>
            <div class="flip-card-back">
              <div class="inner">
                <p>
                  Marina Bwile is an advocate of the High Court of Kenya. She
                  consulted with Teshome Gabre-Mariam Bokan, one of the leading
                  Ethiopian lawyers and later joined Tamrat Assefa Liban Law
                  firm, a very dynamic practice with pro-active lawyers focusing
                  on international work. She supports the work of the firm in
                  advising foreign clients across a wide range of sectors and
                  industries impacted by international trade and investment
                  measures. She is a member of the Law Society of Kenya and the
                  International Bar Association.
                </p>
              </div>
            </div>
          </div>
          <div class="flip-card">
            <div class="flip-card-front">
              <div class="inner">
                <Fade bottom>
                  <h3>Mekdem Belayneh</h3>
                  <p>Partner</p>
                </Fade>
              </div>
            </div>
            <div class="flip-card-back">
              <div class="inner">
                <p>
                  Graduated from Addis Ababa University in the year 2000 and has
                  two Master of Laws degrees, one in Commercial Law from the
                  University of South Africa and another in Constitutional Law
                  from the Addis Ababa University. He is active in litigation
                  and was one of the key participants in the implementation of
                  the Ethiopian Nile Irrigation and Drainage Project, which
                  involved, among other things, a detailed review of applicable
                  laws, acts, and proclamations of Government of Ethiopia,
                  Amhara region and also of World Bank Operational Policies.
                  Furthermore, he has been a local consultant in a United
                  Nations Development Program project for the preparation of a
                  five year strategic plan for the Ethiopian Office of the
                  Ombudsman carried out by a consulting group called Emhazee
                  Global Consult.
                </p>
              </div>
            </div>
          </div>
          <div class="flip-card">
            <div class="flip-card-front">
              <div class="inner">
                <Fade bottom>
                  <h3>Malefiya Tesera</h3>
                  <p>Lawyer</p>
                </Fade>
              </div>
            </div>
            <div class="flip-card-back">
              <div class="inner">
                <p>
                  Malefiya Tesera is a graduate of law of Hawassa University (as
                  an outstanding student of the year) and has masters of laws
                  degree in Comparative Constitutional law, from Central
                  European University. He has over 6 years’ experience four of
                  which as a lecturer at a leading university in Ethiopia. He is
                  a member of the Ethiopian Bar Association.
                </p>
              </div>
            </div>
          </div>
          <div class="flip-card">
            <div class="flip-card-front">
              <div class="inner">
                <Fade bottom>
                  <h3>Yeabsira Mintesinot</h3>
                  <p>Lawyer</p>
                </Fade>
              </div>
            </div>
            <div class="flip-card-back">
              <div class="inner">
                <p>
                  Yeabsira Mintesinot is a graduate of Arba Minch University. He
                  has considerable experience in the practice of law and is
                  passionate on matters relating to corporate and commercial
                  law, immigration, employment law and investment law. He has
                  the ability to comprehend and understand concepts of law and
                  communicate clearly both orally as well as in writing.
                </p>
              </div>
            </div>
          </div>
          <div class="flip-card">
            <div class="flip-card-front">
              <div class="inner">
                <Fade bottom>
                  <h3>Meron Getenah</h3>
                  <p>Lawyer</p>
                </Fade>
              </div>
            </div>
            <div class="flip-card-back">
              <div class="inner">
                <p>
                  Meron Getanah graduated from Arba Minch University in the year
                  2022. She is motivated, organized and a fast learner with keen
                  eyes for detail. She supports other lawyers in the firm on
                  various aspects of law including intellectual property,
                  charities and society’s law and general corporate work.
                </p>
              </div>
            </div>
          </div>
          <div class="flip-card">
            <div class="flip-card-front">
              <div class="inner">
                <Fade bottom>
                  <h3>Fitsum Getachew</h3>
                  <p>Lawyer</p>
                </Fade>
              </div>
            </div>
            <div class="flip-card-back">
              <div class="inner">
                <p>
                  Fitsum Getachew has over 22 years of preofessional experience
                  having served as a judge and later an in-house counsel at the
                  Ethiopian Commodity Exchange where he advised the management
                  on compliance issues and the general operations of the
                  enterprise. He graduated with a Bachelors of Laws degree from
                  Addis Ababa University He specializes on regulatory work,
                  commercial law and commercial litigation.
                </p>
              </div>
            </div>
          </div>
    
          <div class="flip-card">
            <div class="flip-card-front">
              <div class="inner">
                <Fade bottom>
                  <h3>Girma Hailu</h3>
                  <p>Lawyer</p>
                </Fade>
              </div>
            </div>
            <div class="flip-card-back">
              <div class="inner">
                <p>
                  Girma Hailu is an experienced lawyer having worked with
                  various reputable international organisations among them, UNDP
                  and African Development Bank. His work is mainly advisory and
                  the firm consults with him on various legal issues
                  particularly in the area of Energy and Natural Resources law
                </p>
              </div>
            </div>
          </div>
          <div class="flip-card">
            <div class="flip-card-front">
              <div class="inner">
                <Fade bottom>
                  <h3>Wondwossen Sherega</h3>
                  <p>Lawyer</p>
                </Fade>
              </div>
            </div>
            <div class="flip-card-back">
              <div class="inner">
                <p>
                  Wondwossen Sherega is a graduate of Addis Ababa University and
                  also holds a master’s degree in law. He specializes in foreign
                  investment law and corporate law generally having worked with
                  USAID Ethiopia for over 7 years. He dealt with contract law
                  matters, compliance issues and other in-house legal issues
                  that were placed under his dealing.
                </p>
              </div>
            </div>
          </div>
          <div class="flip-card">
            <div class="flip-card-front">
              <div class="inner">
                <Fade bottom>
                  <h3>Nebiat Getahun</h3>
                  <p>IT Specialist</p>
                </Fade>
              </div>
            </div>
            <div class="flip-card-back">
              <div class="inner">
                <p>
                  Nebiat Getahun has extensive experience on information
                  technology matters having served in various large
                  organizations as a Platform Engineer. He graduated from Addis
                  Ababa University and has since developed capabilities in SQL,
                  troubleshooting, system administration, telecommunication,
                  databases, Cisco technologies, network design, network
                  security, firewall, integration, among other.
                </p>
              </div>
            </div>
          </div>
          <div class="flip-card">
            <div class="flip-card-front">
              <div class="inner">
                <Fade bottom>
                  <h3>Getenesh Buzeayehu</h3>
                  <p>Administrator and Accounts Coordinator</p>
                </Fade>
              </div>
            </div>
            <div class="flip-card-back">
              <div class="inner">
                <p>
                Getenesh Bizuayehu is a qualified accountant with extensive experience in account management and general office administration. She is dedicated, hardworking, reliable and passionate about her work.
                </p>
              </div>
            </div>
          </div>
        </body>
      </div>
      <Footer />
    </div>
  );
}

export default team;
