import "./Practice.css";
import Footer from "../Footer";
import Fade from "react-reveal/Fade";
import { Zoom } from "react-reveal";
import React from "react";
import { Parallax } from "react-parallax";
import practice from "../images/Practice.jpg";

function Practice() {
  return (
    <div>
      <head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <script src="https://kit.fontawesome.com/a076d05399.js"></script>
      </head>
      <div className="practice-intro">
        <Parallax bgImage={practice} bgImageAlt="Practice" strength={300}>
          <h1>
            {" "}
            <Zoom>Expertise</Zoom>
          </h1>
        </Parallax>
      </div>
      <section class="practice-page" id="practice-page">
        <div class="desc-box">
          <div class="content">
            <Fade bottom>
              <h3>Services</h3>
            </Fade>
            <p>
              Tamrat Assefa Liban Law Office is a legal practice specialized in
              serving international clients in various areas of law with an
              emphasis in corporate & commercial law, investment law, banking &
              financial law, mergers and acquisitions, intellectual property
              law, business law, energy & natural resources law, international
              trade law, agency and distributorships, aviation law,
              environmental law, anti-trust law, labor and employment law,
              commercial litigation, and non-profit and charitable organizations
              law.
            </p>
            <p>
              We pride ourselves on the superior quality of the services we
              provide. All of our clients receive the same level of personal
              care and attention applied to their case without compromising the
              need for a conscious time management and individualized, focused
              attention.
            </p>
            <hr class="title-page-divider" />
            <div className="services">
              <Fade bottom>
                <h3>
                  <i class="fa fa-university" aria-hidden="true"></i> Banking &
                  Finance
                </h3>
              </Fade>
              <p>
                At Tamrat Assefa Liban Law Office, our team of experienced
                lawyers is well-versed in the intricacies of the banking and
                finance industry in Ethiopia. We have a deep understanding of
                the legal and regulatory framework governing this industry and
                have successfully represented clients in a range of matters,
                from regulatory compliance to complex financing transactions and
                disputes.
              </p>
              <p>
                Our team has a proven track record of providing strategic and
                commercially-minded advice to clients, and we have been
                recognized as leaders in the field of banking and finance law.
                Our services include legal advice on loan agreements, debt and
                equity financing, project finance, capital markets, and
                investment funds.
              </p>
              <p>
                We have extensive experience working with regulatory authorities
                such as the National Bank of Ethiopia, and other relevant
                bodies. Our team understands the regulatory landscape and is
                able to provide clients with practical advice on how to navigate
                these complex regulations.
              </p>
              <p>
                In addition to our legal expertise, we pride ourselves on our
                thought leadership in the banking and finance industry. Our
                lawyers regularly publish articles, speak at conferences, and
                provide commentary on current legal and regulatory issues
                affecting the industry. This allows us to stay at the forefront
                of industry developments and provide our clients with up-to-date
                and commercially minded advice.
              </p>

              <Fade bottom>
                <h3>
                  <i class="fa fa-industry" aria-hidden="true"></i> Mining and
                  Energy law
                </h3>
              </Fade>
              <p>
                Tamrat Assefa Liban Law Office practice in this regards focuses
                on the development, construction and financing of major natural
                resource (oil/gas, mining) and power projects.
              </p>
              <p>
                We have a team of experienced lawyers who specialize in energy
                and natural resources law and are well versed in the legal
                complexities of the energy and natural resources sector. We
                understand the unique challenges faced by businesses operating
                in this field.
              </p>
              <p>
                Our expertise in energy law includes advising clients on
                regulatory compliance, negotiating contracts with suppliers and
                buyers, and providing guidance on energy-related disputes. We
                also have experience in renewable energy projects, such as wind,
                solar, and hydroelectric power.
              </p>
              <p>
                In natural resources law, we assist clients with issues related
                to mining, oil and gas exploration, and environmental
                regulations. Our team is skilled in negotiating and drafting
                agreements for the exploration, development, and exploitation of
                natural resources.
              </p>
              <p>
                We are dedicated to helping our clients navigate the legal
                landscape of the energy and natural resources sector in
                Ethiopia, and we are committed to providing tailored solutions
                that meet their unique needs.
              </p>
              <Fade bottom>
                <h3>
                  <i class="fa fa-lightbulb-o" aria-hidden="true"></i>{" "}
                  Intellectual Property law
                </h3>
              </Fade>
              <p>
                Tamrat Assefa Liban Law Office has a team of experienced lawyers
                who specialize in intellectual property (IP) law. We understand
                the importance of protecting our clients' intellectual property
                assets in Ethiopia and are committed to providing expert legal
                advice and representation.
              </p>
              <p>
                Our expertise in IP law includes advising clients on the
                registration and protection of trademarks, patents, industrial
                designs, utility models copyrights, and trade secrets. We also
                have experience in IP litigation, including enforcement of IP
                rights and defense against infringement claims.
              </p>
              <p>
                We work closely with our clients to develop strategies for
                protecting their IP assets in Ethiopia, including conducting IP
                audits, drafting and negotiating IP-related agreements, and
                advising on IP licensing and technology transfer.
              </p>
              <p>
                We understand Ethiopian IP law and the registration processes
                for trademarks, patents, industrial designs, utility models and
                copyrights.
              </p>
              <p>
                We are dedicated to helping our clients protect and enforce
                their IP rights in Ethiopia and abroad, and we are committed to
                providing tailored solutions that meet their unique needs.
              </p>

              <Fade bottom>
                <h3>
                  <i class="fa fa-handshake-o" aria-hidden="true"></i> Mergers &
                  Acquisitions
                </h3>
              </Fade>
              <p>
                We, at Tamrat Assefa Liban Law Office, have a deep understanding
                of the legal, regulatory, and commercial aspects of M&A
                transactions in Ethiopia, and we are committed to providing our
                clients with exceptional service and advice throughout the M&A
                process.
              </p>
              <p>
                Our lawyers have extensive experience in advising clients on a
                wide range of M&A transactions, including mergers, acquisitions,
                joint ventures, and divestitures. We provide legal guidance on
                all aspects of M&A deals, from structuring and negotiating
                transactions to conducting due diligence, drafting transaction
                documents, advising on antitrust and competition issues, and
                obtaining regulatory approvals.
              </p>
              <p>
                We understand the complexities of M&A transactions in Ethiopia
                and are committed to providing our clients with expert legal
                advice and representation and at the same time provide practical
                solutions that address their legal needs in M&A transactions.
              </p>
              <Fade bottom>
                <h3>
                  <i class="fa fa-volume-control-phone" aria-hidden="true"></i>{" "}
                  Telecommunication
                </h3>
              </Fade>
              <p>
                We understand the complex and rapidly changing legal landscape
                of the telecommunications industry in Ethiopia and are committed
                to providing our clients with expert legal advice and
                representation.
              </p>
              <p>
                Our expertise in telecommunications law includes advising
                clients on regulatory compliance, negotiating contracts with
                telecom operators and service providers, and providing guidance
                on telecommunications-related disputes. We also have experience
                in representing clients in matters related to spectrum
                allocation, licensing, and interconnection.
              </p>
              <p>
                We work closely with our clients to provide practical solutions
                that address their legal needs in the telecommunications
                industry. Our team has a great understanding of Ethiopian
                telecommunications laws and regulations, as well as
                international telecommunications law and policy.
              </p>
              <p>
                We also have experience in advising clients on emerging
                technologies and trends in the telecommunications industry, such
                as 5G and the Internet of Things (IoT). We understand the
                importance of staying up-to-date with technological advancements
                and their legal implications.
              </p>
              <p>
                We are dedicated to helping our clients navigate the
                complexities of telecommunications law in Ethiopia, and we are
                committed to providing tailored solutions.
              </p>
              <Fade bottom>
                <h3>
                  <i class="fa fa-plane" aria-hidden="true"></i> Aviation law
                </h3>
              </Fade>
              <p>
                Tamrat Assefa Liban Law Office understands the complex and
                rapidly changing legal landscape of the aviation industry in
                Ethiopia and are committed to providing our clients with expert
                legal advice and representation.
              </p>
              <p>
                Our expertise in aviation law includes advising clients on
                regulatory compliance, negotiating contracts with airlines and
                airports, and providing guidance on aviation-related disputes.
                We also have experience in aircraft financing and leasing, as
                well as aircraft accidents and incident investigations.
              </p>
              <p>
                We work closely with our clients to provide practical solutions
                that address their legal needs in the aviation industry. Our
                team has a deep understanding of the Ethiopian Civil Aviation
                Authority's regulations and standards, as well as international
                aviation law, including the Montreal Convention.
              </p>
              <p>
                We are dedicated to helping our clients navigate the
                complexities of aviation law in Ethiopia, and we are committed
                to providing tailored solutions that meet their unique needs.
              </p>
              <Fade bottom>
                <h3>
                  <i class="fa fa-users" aria-hidden="true"></i> Charities and
                  Societies law
                </h3>
              </Fade>
              <p>
                Our team understands Ethiopian Charities and Societies laws and
                the regulatory environment for non-profit organizations /
                charities and societies in Ethiopia.
              </p>
              <p>
                Our expertise in Charities and Societies law includes advising
                clients on the formation, registration, and governance of
                charities and societies in Ethiopia. We also provide guidance on
                compliance with Ethiopian laws and regulations related to
                fundraising, accountability, and transparency.
              </p>
              <p>
                We work closely with our clients to develop strategies for
                complying with legal requirements and protecting their
                organizations' reputation and are committed to providing expert
                legal advice and representation.
              </p>
              <p>
                We also have experience in advising charities and societies on
                their relationships with government agencies, donors, and other
                stakeholders. We understand the importance of maintaining a
                positive public image and are committed to helping our clients
                navigate the legal and ethical considerations involved in their
                operations.
              </p>
              <p>
                We are dedicated to helping our clients achieve their charitable
                and societal goals in Ethiopia.
              </p>
              <Fade bottom>
                <h3>
                  <i class="fa fa-truck" aria-hidden="true"></i> Agency and
                  Distributorships
                </h3>
              </Fade>
              <p>
                We understand the legal and commercial considerations involved
                in establishing and managing agency and distributorship
                relationships in Ethiopia and are committed to providing expert
                legal support in this regards.
              </p>
              <p>
                Our expertise in agency and distributorship law includes
                advising clients on the legal and regulatory framework for
                agency and distributorship relationships in Ethiopia,
                negotiating and drafting agency and distributorship agreements,
                and advising on termination and renewal of such relationships.
              </p>
              <p>
                We also have experience in representing both principals and
                agents/distributors in disputes related to agency and
                distributorship relationships, including breach of contract,
                termination, and compensation claims.
              </p>
              <p>
                We work closely with our clients to develop strategies for
                managing agency and distributorship relationships that align
                with their business objectives. Our team has a deep
                understanding of Ethiopian commercial law and the regulatory
                environment for agency and distributorship relationships.
              </p>
              <p>
                We are dedicated to helping our clients establish and manage
                successful agency and distributorship relationships in Ethiopia.
              </p>
              <Fade bottom>
                <h3>
                  <i class="fa fa-money" aria-hidden="true"></i> Investment Law
                </h3>
              </Fade>
              <p>
                Tamrat Assefa Liban Law Office has extensive experience on the
                legal and regulatory framework for investment in Ethiopia. Our
                attorneys are dedicated to providing exceptional legal expertise
                in the practice of investment law.
              </p>
              <p>
                Our attorneys have extensive experience in providing legal
                advice and services to companies and investors who are
                interested in investing in the Ethiopian market.
              </p>
              <p>
                We provide comprehensive legal advice on all aspects of
                investment law, including tax, corporate and banking law,
                securities regulations, foreign investment, and investment
                arbitration. Our team of experienced attorneys will provides the
                most effective legal strategies to help protect both domestic
                and foreign investors’ investments and interests in Ethiopia. We
                ensure that investors’ investments are in line with all
                applicable laws, and that they are aware of all potential risks
                and benefits. With our expertise in investment law, we work with
                our clients to maximize their financial success.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Practice;
