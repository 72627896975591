import React, { useState } from "react";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import "./Navbar.css";
//import { motion } from "framer-motion/dist/framer-motion";
import { useEffect } from "react";
import Logo from "./images/Capture.PNG";

function Navbar() {
  const [navbar, setHeader] = useState("navbar");
  const [logo, setLogo] = useState("logo");
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      setHeader("navbar");
      setLogo("logo");
    } else if (window.scrollY > 70) {
      setHeader("navbar2");
      setLogo("logo2");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <>
      <nav className={navbar}>
        <head>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          />
          <script src="https://kit.fontawesome.com/a076d05399.js"></script>
        </head>
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img
            className={logo}
            alt="logo"
            src={Logo}
            onClick={() => window.scrollTo(0, 0)}
          />
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fa fa-times" : "fa fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item" onClick={() => window.scrollTo(0, 0)}>
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className="nav-item" onClick={() => window.scrollTo(0, 0)}>
            <Link
              to="/practice"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Expertise
            </Link>
          </li>
          <li className="nav-item" onClick={() => window.scrollTo(0, 0)}>
            <Link to="/team" className="nav-links" onClick={closeMobileMenu}>
              The Team
            </Link>
          </li>
          <li className="nav-item" onClick={() => window.scrollTo(0, 0)}>
            <Link
              to="/testimonials"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Testimonials
            </Link>
          </li>

          <li onClick={() => window.scrollTo(0, 0)}>
            <Link
              to="/contact"
              className="nav-links-mobile"
              onClick={closeMobileMenu}
            >
              Contact Us
            </Link>
          </li>
        </ul>
        <Button />
      </nav>
    </>
  );
}

export default Navbar;
